import React from "react";
function Icon({status}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      fill={status? "#ffff" :"#038819 "}
      viewBox="0 -960 960 960"
    >
      <path d="M534-140q-8.088 8-20.044 8Q502-132 494-140L142-492q-5-5-7.5-10.426Q132-507.851 132-514v-286q0-11 8.5-19.5T160-828h286q5 0 10.488 2.167 5.488 2.166 9.512 5.833l352 352q9 9 9.5 21.5T820-426L534-140zm-20.448-20L800-446 446.448-800H160v286l353.552 354zm-253.67-506Q274-666 284-675.882q10-9.883 10-24Q294-714 284.118-724q-9.883-10-24-10Q246-734 236-724.118q-10 9.883-10 24Q226-686 235.882-676q9.883 10 24 10zM160-800z"></path>
    </svg>
  );
}

export default Icon;